/**
 * Module dependencies
 */

import React from 'react';

import { bool, node, shape, string } from 'prop-types';
import serialize from 'serialize-javascript';
import MeliGA from 'nordic/analytics/meli-ga';
import { MelidataTrack } from 'nordic/melidata/melidata-track';
import { Script } from 'nordic/script';
import { Style } from 'nordic/style';
import { Head } from 'nordic/head';
import get from 'lodash/get';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import RedirectApp from 'redirect-app-web';
import { Qualtrics } from 'qualtrics-wrapper';
import { UserProvider } from '../../../../../components/context/user';
import FlashMessage from '../../../../../components/flash-message';
import { StaticPropsProvider } from '../../../../../components/context/static-props';
import { StoreProvider } from '../../../../../store/context';
import rootReducer from '../../../../../reducers/root';
import Schema from '../../../../../components/schema';
import TrackPageVariation from '../../../features/tracking';
import Metadata from '../../../features/metadata';
import Hotjar from '../../../../../components/hotjar';
import GoogleTagManager from '../../../../../components/gtm';
import WrapperEshops from '../../../../../components/eshops';
import { constants } from '../../../../../utils/constants';
import useMShopsTrackingInfo from '../../../../../hooks/use-mshops-tracking-info';
import { getEmbeddedData } from '../../../../helpers/getEmbeddedData';
import { AndesProvider } from '@andes/context';

const { SVG_SPRITES, QUALTRICS_ID } = constants;

/**
 * Vpp Component
 */
const Vpp = ({
  children,
  country,
  csrfToken,
  currentUser,
  deviceType,
  deviceDpr,
  initialState,
  isLegacyBrowser,
  lowEnd,
  translations,
  site,
  siteId,
  baseURL,
  flashMessage,
  hotjar,
  gtm,
  isIE,
  imagePrefix,
  openInApp,
  isGuestMS,
  hasOfficialStoreHeader,
  nonce,
  shopModel,
  page,
  cpCookie,
  coachMarkCookie,
}) => {
  const { layout } = initialState;

  const staticProps = {
    layout,
    flashMessage,
    site,
    siteId,
    lowEnd,
    deviceType,
    deviceDpr,
    country,
    isIE,
    imagePrefix,
    page,
    cpCookie,
    coachMarkCookie,
    isGuestMS,
  };

  const serializeProps = {
    translations,
    site,
    siteId,
    lowEnd,
    deviceType,
    deviceDpr,
    country,
    currentUser,
    initialState,
    csrfToken,
    baseURL,
    flashMessage,
    gtm,
    isIE,
    shopModel,
    imagePrefix,
    openInApp,
    page,
    cpCookie,
    coachMarkCookie,
    hasOfficialStoreHeader,
  };

  const countryAs = country.locale.replace('_', '-') || country.locale;
  const melidataExperiments = get(initialState, 'components.track.melidata_event.experiments', {});
  melidataExperiments['pdp/performance-improvements'] = '4219';
  const hasEshopData =
    initialState?.storefront?.appearance?.hidden === false &&
    initialState?.storefront?.appearance?.children?.length > 0;
  useMShopsTrackingInfo(get(initialState, 'shopsDefaultTrackingData'));

  const hasTrafficSniffer = initialState?.components?.traffic_sniffer?.state?.toLowerCase() === 'visible';
  const hasClips = !!initialState?.components?.gallery?.clips?.shorts?.length;

  return (
    <>
      {openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <Hydrator whenIdle>
        <Schema schemaData={initialState.schema} />
      </Hydrator>
      {hasClips && (
        <Head>
          <link
            href="https://http2.mlstatic.com/frontend-assets/shorts-nordic-viewer/videojs.4ff5eea5e00eaf6f87b4.css"
            rel="stylesheet"
          />
          <Script src="https://http2.mlstatic.com/frontend-assets/shorts-nordic-viewer/videojs.a23f701605564af23722.js" />
        </Head>
      )}
      {flashMessage && flashMessage.text && <FlashMessage {...flashMessage} />}
      <div className="ui-pdp">
        <MeliGA
          section={get(initialState, 'components.track.analytics_event.section')}
          page={get(initialState, 'components.track.analytics_event.page')}
          business={get(initialState, 'components.track.analytics_event.custom_dimensions.business')}
          pageVertical={get(initialState, 'components.track.analytics_event.custom_dimensions.pageVertical')}
          pathFromRoot={get(initialState, 'components.track.analytics_event.custom_dimensions.pathFromRoot')}
          dimensions={get(initialState, 'components.track.analytics_event.custom_dimensions.customDimensions')}
        />
        <MelidataTrack
          path={get(initialState, 'components.track.melidata_event.path')}
          event_data={get(initialState, 'components.track.melidata_event.event_data')}
          experiments={melidataExperiments}
        />

        <Style href={`vpp.${deviceType}.css`} critical={deviceType === 'mobile'} />
        <Script inline on="now">
          {`
          window.requestIdleCallback =
            window.requestIdleCallback ||
            function(cb) {
                var start = Date.now();
                return setTimeout(function() {
                    cb({
                        didTimeout: false,
                        timeRemaining: function() {
                            return Math.max(0, 50 - (Date.now() - start));
                        },
                    });
                }, 1);
            };

          window.cancelIdleCallback =
            window.cancelIdleCallback ||
            function(id) {
                clearTimeout(id);
            };
          window.__PRELOADED_STATE__ = ${serialize(serializeProps, { isJSON: true })};
        `}
        </Script>
        {isLegacyBrowser && <Script src="polyfill.js" />}
        {isIE && <Script src="polyfills-ie.js" />}
        <Script src={`vpp.${deviceType}.js`} />
        {hasTrafficSniffer && (
          <Script
            src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/33944383e41d/challenge.js"
            crossOrigin={null}
            defer
          />
        )}
        <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
          {`window.viewability.setNonceForCSP('${nonce}')`}
        </Script>
        <Qualtrics interceptId={QUALTRICS_ID} embeddedData={getEmbeddedData(initialState)} />
        <Hotjar {...hotjar} extraData={get(initialState, 'components.track.hotjar_event')} />
        <GoogleTagManager {...gtm} dataLayer={get(initialState, 'components.track.gtm_event')} />
        <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
        <Script src="errorhandler.js" priority={2} on="now" inline />
        <Script priority={1} on="now">
          {
            // Registrar la app para el tag de error handler
            'window.__eh_app_tag__ = "vpp";'
          }
        </Script>
        <AndesProvider locale={countryAs} csrfToken={csrfToken}>
          <UserProvider value={currentUser}>
            <StaticPropsProvider value={staticProps}>
              <StoreProvider siteId={siteId} defaultState={initialState} reducer={rootReducer}>
                {hasEshopData && (
                  <WrapperEshops show={hasEshopData} {...initialState.storefront.appearance} siteId={siteId} />
                )}
                {children}
                <Hydrator whenIdle>
                  <TrackPageVariation />
                </Hydrator>
                <Hydrator whenIdle>
                  <Metadata />
                </Hydrator>
              </StoreProvider>
            </StaticPropsProvider>
          </UserProvider>
        </AndesProvider>
      </div>
    </>
  );
};

Vpp.propTypes = {
  baseURL: string.isRequired,
  children: node.isRequired,
  country: shape({
    decimal_separator: string.isRequired,
    thousands_separator: string.isRequired,
    locale: string.isRequired,
  }).isRequired,
  csrfToken: string.isRequired,
  currentUser: shape({
    nickname: string.isRequired,
  }),
  deviceType: string.isRequired,
  deviceDpr: string,
  flashMessage: string,
  gtm: shape(),
  hotjar: shape(),
  initialState: shape().isRequired,
  isLegacyBrowser: bool,
  lowEnd: bool.isRequired,
  site: string.isRequired,
  siteId: string.isRequired,
  translations: shape(),
  shopModel: shape(),
  isIE: bool,
  imagePrefix: string,
  openInApp: shape({
    shouldRedirect: bool,
  }).isRequired,
  page: string,
  cpCookie: string,
  coachMarkCookie: string,
  isGuestMS: string,
  hasOfficialStoreHeader: bool,
  nonce: string,
};

Vpp.defaultProps = {
  currentUser: null,
  deviceDpr: null,
  flashMessage: null,
  gtm: null,
  hotjar: null,
  isLegacyBrowser: true,
  translations: {},
  shopModel: {},
  isIE: false,
  imagePrefix: null,
  hasOfficialStoreHeader: false,
  page: null,
  cpCookie: null,
  coachMarkCookie: null,
  isGuestMS: null,
  nonce: null,
};

/**
 * Inject i18n context as props into Vpp.
 */
export default Vpp;
