import React from 'react';
import { bool, shape, string, func } from 'prop-types';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCardHorizontalTitleFirst } from './recomendations-card';

const Recommendations = ({
  isPolycard,
  thousandSeparator,
  decimalSeparator,
  runCatchErrorBoundary = () => {},
  ...props
}) => {
  try {
    const apiRecommendations = isPolycard
      ? props?.apiresponse?.recommendation_info?.polycards
      : props?.apiresponse?.recommendation_info?.recommendations;
    const items = apiRecommendations?.length || 0;
    const countSlides = items < 3 && items !== 0 ? items : 3;

    return (
      <div className={items === 1 ? 'ui-pdp-container--top ui-pdp-container--card-one-item' : null}>
        <RecommendationsCarouselSnapped
          Card={RecommendationsCardHorizontalTitleFirst}
          IconFactory={RecommendationsIconFactory}
          criticalImages={countSlides}
          slidesPerView={countSlides}
          contextProps={{
            type: 'list-card',
            thousandSeparator,
            decimalSeparator,
          }}
          cardProps={{
            polyClassName: 'recos-polycard poly-card--mobile',
          }}
          isPolycard={isPolycard}
          {...props}
        />
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  apiresponse: shape({}),
  runCatchErrorBoundary: func,
};

export default Recommendations;
