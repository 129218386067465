import React from 'react';
import classnames from 'classnames';
import { arrayOf, string, shape, number, func } from 'prop-types';
import { ActionCollapsable } from '../action-collapsable';
import Image from '../image';
import useTrackView from '../../hooks/use-track-view';

const namespace = 'pdp-enhanced-descriptions';

const EnhancedDescriptions = ({ title, pictures, viewPortTrack, action, maxShowPixels, runCatchErrorBoundary }) => {
  try {
    const viewRef = useTrackView(viewPortTrack, { threshold: 0 });
    const showTitle = title.text && title.text.length > 0;

    return (
      <div className={`${namespace}__content`} ref={viewRef}>
        {showTitle && (
          <h2 data-testid="title" className={`${namespace}__title`}>
            {title.text}
          </h2>
        )}
        <ActionCollapsable
          action={action}
          scrollToTop={false}
          maxCollapsed={maxShowPixels}
          collapseBy="pixels"
          className={classnames(namespace)}
          gradientType="small"
        >
          <div className={`${namespace}__pictures`}>
            {pictures.map(picture => (
              <div key={picture?.id} className={`${namespace}__image-wrapper`}>
                <Image {...{ ...picture, alt: '' }} className={`${namespace}__image`} />
              </div>
            ))}
          </div>
        </ActionCollapsable>
      </div>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

EnhancedDescriptions.propTypes = {
  viewPortTrack: shape({}),
  action: shape({
    target: string,
    label: shape({
      text: string.isRequired,
      color: string.isRequired,
    }).isRequired,
  }).isRequired,
  title: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  pictures: arrayOf(
    shape({
      id: string.isRequired,
      alt: string.isRequired,
      src: string.isRequired,
      src2x: string,
    }),
  ).isRequired,
  maxShowPixels: number.isRequired,
  runCatchErrorBoundary: func,
};

EnhancedDescriptions.defaultProps = {
  open_image_track: null,
  viewPortTrack: null,
  runCatchErrorBoundary: () => {},
};

export default EnhancedDescriptions;
